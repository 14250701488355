import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hub-video-overlay',
  templateUrl: './video-overlay.component.html',
  styleUrls: ['./video-overlay.component.scss'],
})
export class VideoOverlayComponent {
  canplay = false; // to hide glitched black video player when video is loading
  constructor(@Inject(MAT_DIALOG_DATA) public data: { videoSrc: string }) {}
}
