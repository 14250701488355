import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AssetLoadingService, AssetLoadingStatus } from '@ethic/ng-kit';
import { Observable, ReplaySubject } from 'rxjs';

export interface GoogleAnalyticsSettings {
  gstId: string;
}

export const GOOGLE_ANALYTICS_SETTINGS_TOKEN = new InjectionToken<GoogleAnalyticsSettings>(
  'google-analytics-settings'
);

/**
 * Primary intention of this service is to move Google Analytics initializing
 * logic from index.html to an Angular service. It allows to initialize
 * Universal Analytics (gtag.js) with different environment-based
 * GA_MEASUREMENT_ID's (gstId's).
 *
 * After having initialised Google Analytics with this service, we can either
 * use the native `gtag` object to track events, or use a wrapping library
 * like Angulartics2.
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsInitializerService {
  private initTriggered = false;
  private initResult$ = new ReplaySubject<true>();

  private readonly gtagJsBaseUrl = 'https://www.googletagmanager.com/gtag/js';

  constructor(
    @Inject(GOOGLE_ANALYTICS_SETTINGS_TOKEN) private settings: GoogleAnalyticsSettings,
    @Inject(DOCUMENT) private document: Document,
    private assetLoader: AssetLoadingService
  ) {
    this.initResult$.subscribe({ error: (err) => this.logInitWarning(err) });
  }

  init(): Observable<true> {
    if (this.initTriggered) {
      return this.initResult$;
    }

    this.initTriggered = true;

    const gstId = this.settings?.gstId;
    if (!gstId) {
      this.initResult$.error('Google Analytics gstId not provided.');
      return this.initResult$;
    }

    const window = this.document.defaultView as Window & typeof globalThis & { dataLayer: any[] };

    if (!window) {
      this.initResult$.error('Global window object not available.');
      return this.initResult$;
    }

    window.dataLayer = window.dataLayer || [];
    window['gtag'] = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
    window['gtag']('js', new Date());
    window['gtag']('config', gstId);

    const gtagJsFullUrl = this.gtagJsBaseUrl + '?id=' + gstId;
    this.assetLoader.loadScripts([gtagJsFullUrl]).subscribe(([result]) => {
      if (result.status === 'failed') {
        this.initResult$.error('Cannot load gtag.js.');
      } else if (result.status === AssetLoadingStatus.Loaded) {
        this.initResult$.next(true);
        this.initResult$.complete();
      }
    });

    return this.initResult$;
  }

  private logInitWarning(reason: string) {
    console.warn(`Cannot initialize Google Analytics. ${reason}`);
  }
}
