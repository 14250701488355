import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-mini';

@Pipe({
  name: 'ethTimeFromNow',
})
export class TimeFromNowPipe implements PipeTransform {
  transform(value: any): any {
    return moment(value).fromNow();
  }
}
