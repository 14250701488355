import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethHighlight',
  pure: true,
})
export class HighlightTextPipe implements PipeTransform {
  transform(value: string, args: string): string {
    if (!args) {
      return value;
    }
    let re: RegExp;
    try {
      re = new RegExp(args, 'gi');
    } catch (e) {
      return value;
    }
    return value.replace(re, '<mark>$&</mark>');
  }
}
