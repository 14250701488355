import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethPercentPrecise',
  pure: true,
})
export class AppPercentPrecisePipe implements PipeTransform {
  constructor(private percentPipe: PercentPipe) {}

  transform(value: any, zeroValueDisplay?: string) {
    if (value === 0 && zeroValueDisplay) {
      return zeroValueDisplay;
    }
    return this.percentPipe.transform(value, '.2');
  }
}
