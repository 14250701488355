import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ethPreventDefault]',
})
export class ClickPreventDefaultDirective {
  @HostListener('click', ['$event'])
  onClick(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
