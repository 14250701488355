import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethCurrencyInput',
})
export class CurrencyInputPipe implements PipeTransform {
  transform(ccyCode: string): string {
    let prefix = '';
    if (ccyCode) {
      prefix = getCurrencySymbol(ccyCode, 'wide') + ' ';
    }
    return prefix;
  }
}
