import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserProfile } from '../models/user-profile';
import { ProfileDataService } from './profile-data.service';

@Injectable({ providedIn: 'root' })
export class ProfileResolver implements Resolve<UserProfile> {
  constructor(private profileDataService: ProfileDataService) {}

  resolve(): Observable<UserProfile> {
    return this.profileDataService.getProfile().pipe(take(1));
  }
}
