import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { WindowService } from '../services/window/window.service';

@Directive({
  selector: '[ethFootnotesVisible]',
})
export class FootnotesVisibleDirective {
  isTablet: boolean;
  constructor(
    private tempRef: TemplateRef<any>,
    private viewCont: ViewContainerRef,
    private windowService: WindowService
  ) {
    this.windowService.isTablet$.pipe(take(1)).subscribe((isTablet) => {
      this.isTablet = isTablet;
    });
  }

  @Input() set ethFootnotesVisible(footnotes: string[] | string) {
    if (footnotes && footnotes[0] && this.isTablet) {
      this.viewCont.createEmbeddedView(this.tempRef);
    } else {
      this.viewCont.clear();
    }
  }
}
