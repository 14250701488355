import { Pipe, PipeTransform } from '@angular/core';
import { kebabCase } from 'lodash-es';

@Pipe({
  name: 'ethSectorIcon',
  pure: true,
})
export class SectorIconPipe implements PipeTransform {
  transform(value: string): string {
    return `theme-sector-${kebabCase(value)}`;
  }
}
