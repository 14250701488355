import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AnalyticsService, PageTitleService } from '@hub/core';
import { filter, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'hub-root',
  templateUrl: './hub-app.component.html',
  styleUrls: ['./hub-app.component.scss'],
})
export class HubAppComponent {
  title = 'hub';

  constructor(
    pageTitle: PageTitleService,
    analytics: AnalyticsService,
    private swUpdate: SwUpdate,
    private router: Router
  ) {
    pageTitle.init('Sustainability for Everyone');

    analytics.init();

    this.initVersionCheck();
  }

  private initVersionCheck() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          switchMap(() =>
            this.router.events.pipe(
              filter(
                (event) => event instanceof NavigationEnd || event instanceof NavigationCancel
              ),
              take(1)
            )
          )
        )
        .subscribe(() => {
          window.location.reload();
        });
    }
  }
}
