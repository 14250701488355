import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PageTitleService {
  baseTitle: string;
  page: string;
  page$ = new Subject<string | null>();
  entityName: string;

  constructor(private router: Router) {}

  init(baseTitle: string) {
    this.baseTitle = baseTitle;

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.entityName = '';
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const page = this.getDeepestProp('title', this.router.routerState.snapshot.root);
          this.page = page;
          this.page$.next(page);
          this.updatPageTitle();
        })
      )
      .subscribe();
  }

  updatPageTitle() {
    let title: string;
    if (!this.page) {
      title = this.baseTitle;
    } else {
      const currentPage = this.entityName ? `${this.page}: ${this.entityName}` : this.page;
      title = [currentPage, this.baseTitle].join(' | ');
    }
    window.document.title = title;
  }

  setEntityName(name: string) {
    this.entityName = name;
    this.updatPageTitle();
  }

  private getDeepestProp(propName: string, routeSnapshot: ActivatedRouteSnapshot) {
    let propValue = routeSnapshot.data ? routeSnapshot.data[propName] : '';
    if (routeSnapshot.firstChild) {
      propValue = this.getDeepestProp(propName, routeSnapshot.firstChild) || propValue;
    }
    return propValue;
  }
}
