import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InfiniteScrollService {
  private containerSelector = '.mat-drawer-content.mat-sidenav-content';
  private containerElement: HTMLElement | null;
  private scrollStream: Observable<Event>;
  private delta = 4;
  private debounceTime = 200;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.init();
  }

  onScrollBottom(): Observable<Event> {
    return this.scrollStream;
  }

  private init() {
    this.containerElement = this.document.querySelector(this.containerSelector);
    const scrollElement = this.containerElement || this.document.scrollingElement;

    if (!scrollElement) {
      return;
    }

    this.scrollStream = fromEvent(scrollElement, 'scroll').pipe(
      filter(() => {
        const clientRect = scrollElement.getBoundingClientRect();
        if (this.containerElement) {
          return (
            this.containerElement.scrollHeight - this.containerElement.scrollTop <
            clientRect.bottom + 10
          );
        } else if (this.document.scrollingElement) {
          return (
            this.document.scrollingElement.scrollHeight - this.document.scrollingElement.scrollTop >
            this.delta
          );
        } else {
          return false;
        }
      }),
      debounceTime(this.debounceTime)
    );
  }
}
