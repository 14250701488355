import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
/**
 * directive is used as an indicator for factsheet pages and pdf-viewer,
 * indicating that the PDF page is ready to use.
 * Used as indicator in Atom and e2e.
 */
@Directive({
  selector: '[ethPdfReady]',
})
export class PdfReadyDirective {
  @Input() set ethPdfReady(pdfReady: boolean) {
    if (pdfReady) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'pdfReady', 'true');
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'pdfReady');
    }
  }

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}
}
