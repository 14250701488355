import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InsideIframeCheckerService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  // https://app.clickup.com/t/344k9a0 avoid memory leak when platform app is inside iframe which is the platform app
  isInsideIframe(): boolean {
    return this.document.location.ancestorOrigins.length > 1;
  }
}
