import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { HubCommonModule } from '../hub-common/hub-common.module';
import { UserInitialsPipe } from './pipes/user-initials.pipe';
import { TopBarComponent } from './top-bar.component';

@NgModule({
  declarations: [TopBarComponent, UserInitialsPipe],
  imports: [
    HubCommonModule,
    MatTabsModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatDividerModule,
    MatButtonModule,
    RouterModule,
  ],
  exports: [TopBarComponent],
})
export class TopBarModule {}
