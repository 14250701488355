import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { OverlayConfig } from '@angular/cdk/overlay/overlay-config';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { defaultsDeep } from 'lodash-es';
import { tooltipPositions } from './tooltip-positions.config';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  tooltipRef: OverlayRef;

  constructor(private overlay: Overlay) {}

  openTooltip<T>(
    component: ComponentType<T>,
    target: HTMLElement,
    options: OverlayConfig = {},
    inputData: Record<string, any>
  ) {
    if (this.tooltipRef) {
      this.tooltipRef.detach();
    }

    const overlayConfig = defaultsDeep(options, {
      panelClass: 'mat-elevation-z7',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(target)
        .withViewportMargin(20)
        .withPositions(tooltipPositions),
    } as OverlayConfig);

    this.tooltipRef = this.overlay.create(overlayConfig);
    const componentPortal = new ComponentPortal(component);
    const componentRef = this.tooltipRef.attach(componentPortal);
    // set component inputs
    Object.keys(inputData).forEach((key) => (componentRef.instance[key] = inputData[key]));

    return componentRef;
  }

  closeTooltip() {
    if (!this.tooltipRef) {
      return;
    }

    this.tooltipRef.detach();
  }
}
