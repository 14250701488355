import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@hub-environments/environment';
import { hubInterceptorProviders, PageTitleService, ProfileResolver } from '@hub/core';
import { Angulartics2Module } from 'angulartics2';
import { GlobalErrorHandler } from './core/services/error-handler';
import { GOOGLE_ANALYTICS_SETTINGS_TOKEN } from './core/services/google-analytics-initializer.service';
import { DevModeGuard } from './features/login-dev/login-dev/dev-mode.guard';
import { HubAppComponent } from './hub-app.component';
import { hubAppRoutes } from './hub-app.routing';
import { PageNotFoundModule } from './shared/page-not-found/page-not-found.module';
import { SocialShareModule } from './shared/social-share';
import { VideoOverlayComponent } from './shared/video-overlay/video-overlay.component';

@NgModule({
  declarations: [HubAppComponent, VideoOverlayComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(hubAppRoutes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'top',
    }),
    BrowserAnimationsModule,
    PageNotFoundModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
    Angulartics2Module.forRoot({
      pageTracking: { autoTrackVirtualPages: false },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SocialShareModule.forRoot({ facebook: environment.facebook }),
  ],
  providers: [
    ProfileResolver,
    PageTitleService,
    hubInterceptorProviders,
    DevModeGuard,
    {
      provide: GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useValue: environment.googleAnalytics,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [HubAppComponent],
})
export class HubAppModule {}
