import { Component } from '@angular/core';
import {
  colorAnimation,
  ColorAnimationPhase,
  ColorAnimationService,
} from '@ethic/shared/color-animation';

@Component({
  selector: 'hub-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  animations: [colorAnimation],
})
export class PageNotFoundComponent {
  animationPhase: ColorAnimationPhase = 'init';

  constructor(public colorAnimationService: ColorAnimationService) {}

  restartAnimation() {
    this.animationPhase = this.colorAnimationService.getAnimationPhase();
  }
}
