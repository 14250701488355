<div class="snack-bar-wrapper">
  <span class="snack-bar__action {{ action }}" [ngSwitch]="action">
    <span *ngSwitchCase="'error'"><mat-icon>error</mat-icon></span>
    <span *ngSwitchCase="'warning'"><mat-icon>warning</mat-icon></span>
    <span *ngSwitchCase="'success'"><mat-icon>done</mat-icon></span>
    <span *ngSwitchCase="'info'"><mat-icon>check_circle</mat-icon></span>
    <span *ngSwitchDefault></span>
  </span>
  <span class="snack-bar__message"> {{ message }} </span>
</div>
