<div class="top-bar-container">
  <div class="left-items">
    <div routerLink="/dashboard" class="logo"></div>
    <div class="slogan" routerLink="/dashboard">
      <span
        [@colorAnimation]="colorAnimationService.getAnimationConfig(animationPhase)"
        (@colorAnimation.done)="restartAnimation()"
        >Sustainability</span
      >
      for Everyone
    </div>
  </div>

  <div class="top-bar-navigation" fxLayout fxLayoutGap="16px" fxLayoutAlign="flex-end">
    <nav fxLayout>
      <ul class="main-menu" fxLayout fxLayoutGap="4px" fxFlex>
        <li *ngFor="let item of items" fxLayout fxLayoutAlign="stretch center">
          <a
            fxFlex="0 0 auto"
            class="nav-menu-item"
            [routerLink]="item.link"
            routerLinkActive="active"
            >{{ item.name }}</a
          >
        </li>
      </ul>
    </nav>

    <div class="user-photo small-photo" fxFlexAlign="center" [matMenuTriggerFor]="userMenu">
      {{ profile | hubUserInitials }}
    </div>
  </div>

  <div class="top-bar_menu_icon">
    <button class="sidenav-button" mat-icon-button (click)="toggleMobileNav()">
      <mat-icon>{{ showNav ? 'close' : 'menu' }}</mat-icon>
    </button>
  </div>

  <nav class="top-bar_mobile-navigation" *ngIf="showNav">
    <ul class="top-bar_mobile-menu main-menu">
      <li *ngFor="let item of mobileLinks" fxLayout fxLayoutAlign="stretch center">
        <a
          fxFlex="0 0 auto"
          [routerLink]="item.link"
          routerLinkActive="active"
          (click)="showNav = false"
          >{{ item.name }}</a
        >
      </li>
    </ul>

    <ul class="top-bar_settings light-text-color">
      <li>Hi {{ profile.firstName }}!</li>
      <li><a routerLink="/profile">My mission</a></li>
      <li><a routerLink="/settings">Account settings</a></li>
      <li><a href="javascript:void(0)" (click)="logOut()">Sign out</a></li>
    </ul>

    <div class="powered-by light-text-color">
      <span
        >Powered by
        <a [href]="ethicLink" (click)="trackLink(ethicLink)" target="_blank">Ethic</a></span
      >
    </div>
  </nav>

  <mat-menu #userMenu="matMenu" class="top-bar-user-menu">
    <div
      mat-menu-item
      fxLayout="row"
      fxLayoutGap="16px"
      class="menu-header profile-info"
      routerLink="/profile"
    >
      <div class="user-photo big-photo" fxFlexAlign="center">{{ profile | hubUserInitials }}</div>
      <div fxLayout="column" fxLayoutAlign="center" *ngIf="profile">
        <div>
          <b>{{ profile.firstName }} {{ profile.lastName }}</b>
        </div>
        <div class="mat-caption">{{ profile.email }}</div>
        <button *ngIf="false" mat-stroked-button class="button-smallest">My Mission</button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <button mat-menu-item (click)="layoutService.toggleThemeMode()" *ngIf="false">
      <mat-icon>{{ layoutService.isDarkMode ? 'light_mode' : 'dark_mode' }}</mat-icon>
      Switch {{ layoutService.isDarkMode ? 'day' : 'night' }} mode
    </button>

    <button mat-menu-item routerLink="/profile" class="profile-menu-item">
      <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
      My mission
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item routerLink="/settings" class="profile-menu-item">
      <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
      Account settings
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item (click)="logOut()" class="profile-menu-item">
      <mat-icon fontSet="material-icons-outlined">logout</mat-icon>
      Sign out
    </button>
  </mat-menu>
</div>
