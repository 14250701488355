import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WindowService } from '@ethic/ng-kit';
import { DeployUrlService } from '@ethic/shared/core/services/deploy-url/deploy-url.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.engineApiSettings.baseUrl;
  private loginBaseUrl = environment.authSettings.loginPagesBaseUrl;
  private loginPage = environment.authSettings.loginPage;

  constructor(
    private httpClient: HttpClient,
    private location: Location,
    private deployUrlService: DeployUrlService,
    private windowService: WindowService
  ) {}

  logout() {
    this.httpClient.post(this.baseUrl + '/users/logout', {}).subscribe(() => {
      const window = this.windowService.getWindow();
      if (this.windowService.isLocalhost()) {
        window.location.href = `/login-dev?return_to=` + this.location.path();
      } else {
        window.location.href =
          this.loginBaseUrl +
          `${this.loginPage}?return_to=${this.deployUrlService.deployUrl}` +
          this.location.path();
      }
    });
  }
}
