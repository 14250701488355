import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethShowAnchorLink',
  pure: true,
})
export class ShowAnchorLinkPipe implements PipeTransform {
  regexAnchor = /<a\s[^>]*>([\s\S^<]*?)<\/a>/g;

  transform(value: string): string {
    let output = value;
    let match;
    while ((match = this.regexAnchor.exec(value))) {
      const anchorTag = match[0];
      const anchorText = match[1];
      output = output.replace(anchorTag, anchorText);
    }

    return output;
  }
}
