import { ExtendedScrollToOptions } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  private resizeSubject: Subject<any>;
  private scrollToSubject = new Subject<ExtendedScrollToOptions>();

  private readonly mobileBreakpointWidth = 769;
  private readonly tabletBreakpointWidth = 1200;

  get scrollTo$(): Observable<ExtendedScrollToOptions> {
    return this.scrollToSubject.asObservable();
  }

  constructor() {
    this.resizeSubject = new Subject();
    fromEvent(window, 'resize').subscribe((event) => this.resize(event));
  }

  private resize(event: Event) {
    const window: Window = <Window>event.target;
    const dimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.resizeSubject.next(dimensions);
  }

  scrollTo(options: ExtendedScrollToOptions) {
    this.scrollToSubject.next(options);
  }

  onResize(debounceDuration: number = 150): Observable<any> {
    return this.resizeSubject.asObservable().pipe(debounceTime(debounceDuration));
  }

  get isMobile$(): Observable<boolean> {
    return this.onResize().pipe(
      map((dimensions) => dimensions.width < this.mobileBreakpointWidth),
      distinctUntilChanged(),
      startWith(window.innerWidth < this.mobileBreakpointWidth)
    );
  }

  get isLargerThanMobile$(): Observable<boolean> {
    return this.onResize().pipe(
      map((dimensions) => dimensions.width >= this.mobileBreakpointWidth),
      distinctUntilChanged(),
      startWith(window.innerWidth >= this.mobileBreakpointWidth)
    );
  }

  get isMobileDevice(): boolean {
    return /Android|iPhone|iPad/i.test(navigator.userAgent);
  }

  get isTablet$(): Observable<boolean> {
    return this.onResize().pipe(
      map((dimensions) => dimensions.width < this.tabletBreakpointWidth),
      distinctUntilChanged(),
      startWith(window.innerWidth < this.tabletBreakpointWidth)
    );
  }

  get isLargerThanTablet$(): Observable<boolean> {
    return this.onResize().pipe(
      map((dimensions) => dimensions.width >= this.tabletBreakpointWidth),
      distinctUntilChanged(),
      startWith(window.innerWidth >= this.tabletBreakpointWidth)
    );
  }

  reload() {
    window.location.reload();
  }

  isLocalhost(): boolean {
    const names = ['localhost', '127.0.0.1'];
    return names.some((name) => window.location.hostname.includes(name));
  }

  openWindow(path: string) {
    window.open(path);
  }

  getWindow(): Window {
    return window;
  }
}
