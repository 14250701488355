import { Component, OnInit } from '@angular/core';
import {
  colorAnimation,
  ColorAnimationPhase,
  ColorAnimationService,
} from '@ethic/shared/color-animation';
import { SharedLinks } from '@ethic/shared/configurations';
import { AnalyticsService, HubAnalyticsEvents, LayoutService } from '@hub/core';
import { UserProfile } from '@hub/core/models/user-profile';
import { ProfileDataService } from '@hub/core/services/profile-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { UserService, WebflowDataService } from '../../core';

interface MenuItem {
  name: string;
  link: string;
  badge?: number;
}

@UntilDestroy()
@Component({
  selector: 'hub-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  animations: [colorAnimation],
})
export class TopBarComponent implements OnInit {
  readonly ethicLink = SharedLinks.EthicMarketingSite;

  profile: UserProfile;
  items: MenuItem[] = [
    {
      name: 'Dashboard',
      link: '/dashboard',
    },
  ];
  mobileLinks: MenuItem[] = [
    {
      name: 'Dashboard',
      link: '/dashboard',
    },
  ];
  showNav = false;
  animationPhase: ColorAnimationPhase = 'init';

  constructor(
    public layoutService: LayoutService,
    private userService: UserService,
    private profileService: ProfileDataService,
    public colorAnimationService: ColorAnimationService,
    private analytics: AnalyticsService,
    private webflowDataService: WebflowDataService
  ) {}

  ngOnInit(): void {
    this.webflowDataService
      .getOrderedHeadersList()
      .pipe(take(1))
      .subscribe((impactAreas) => {
        const impactNavItems = impactAreas.map((area) => ({
          name: area.slug,
          link: '/impact-areas/' + area.slug,
        }));
        this.mobileLinks.push(...impactNavItems);
        this.items.push(...impactNavItems);
      });
    this.profileService
      .getProfile()
      .pipe(untilDestroyed(this))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;
        }
      });
  }

  logOut() {
    this.userService.logout();
  }

  toggleMobileNav() {
    this.showNav = !this.showNav;
  }

  trackLink(url: string) {
    this.analytics.trackEvent(HubAnalyticsEvents.LinkClicked, { url });
  }

  restartAnimation() {
    this.animationPhase = this.colorAnimationService.getAnimationPhase();
  }
}
