import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethRemoveHtmlLinks',
  pure: true,
})
export class RemoveHtmlLinksPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/<a\b[^>]*>/i, '').replace(/<\/a>/i, '');
  }
}
