import { Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from '@hub/core/models/user-profile';

@Pipe({
  name: 'hubUserInitials',
  pure: true,
})
export class UserInitialsPipe implements PipeTransform {
  transform(profile: UserProfile | null): string {
    if (!profile) {
      return '';
    }

    return [
      profile.firstName.substring(0, 1).toUpperCase(),
      profile.lastName.substring(0, 1).toUpperCase(),
    ].join('');
  }
}
