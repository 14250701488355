import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethHtmlLinkTarget',
  pure: true,
})
export class HtmlLinkTargetPipe implements PipeTransform {
  transform(value: string, target: '_blank' | '_self' | '_parent' | '_top' = '_blank'): string {
    return value.replace(/<a/g, `<a target="${target}"`);
  }
}
