export enum SharedLinks {
  EthicMarketingSite = 'https://ethic.com',
  EthicCareers = 'https://ethic.com/careers',
  EducationHub = 'https://ethic.tech/education-hub',

  Helpdesk = 'https://ethic.zendesk.com/hc/en-us',

  TermsOfService = 'https://ethic.com/legal/terms-of-service',
  PrivacyPolicy = 'https://ethic.com/legal/privacy-policy',

  S4ELandingPage = 'https://ethic.com/community',

  AdvPart2a = 'https://ethic.docsend.com/view/nx3rnppjpki88rhq',
  TradingWithEthicFaq = 'https://ethic.docsend.com/view/hwmfptj2bskkxw3y',
}

export enum SharedEthicEmails {
  Clients = 'clients@ethic.com',
  Partnership = 'partnership@ethic.com',
  Community = 'community@ethic.com',
  Compliance = 'compliance@ethic.com',
}
