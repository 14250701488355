<video
  [class.hidden]="!canplay"
  class="video-overlay"
  (canplay)="canplay = true"
  autoplay
  playsinline
  controls
  *ngIf="data.videoSrc"
>
  <source [src]="data.videoSrc" />
</video>
<mat-icon [class.hidden]="!canplay" class="close-icon" mat-dialog-close>close</mat-icon>
