import { NgModule } from '@angular/core';
import { HubCommonModule } from '../hub-common/hub-common.module';
import { PageContentDirective } from './page/page-content.directive';
import { PageFooterDirective } from './page/page-footer.directive';
import { PageHeaderDirective } from './page/page-header.directive';
import { PageComponent } from './page/page.component';

@NgModule({
  declarations: [PageComponent, PageHeaderDirective, PageFooterDirective, PageContentDirective],
  imports: [HubCommonModule],
  exports: [PageComponent, PageHeaderDirective, PageFooterDirective, PageContentDirective],
})
export class LayoutModule {}
