import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { WindowService } from '@ethic/ng-kit';

@Injectable({
  providedIn: 'root',
})
export class DevModeGuard implements CanLoad {
  constructor(private windowService: WindowService) {}
  canLoad() {
    return this.windowService.isLocalhost();
  }
}
