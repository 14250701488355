import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HubAnalyticsEvents } from './analytics-events';
import { AnalyticsService } from './analytics.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const errorHandlerAnalytics = this.injector.get(AnalyticsService, null);

    const props = {
      ['Error Message']: error.message,
    };

    if (error instanceof HttpErrorResponse) {
      props['Response Code'] = error.error;
    } else {
      props['Response Code'] = error.stack;
    }

    errorHandlerAnalytics?.trackEvent(HubAnalyticsEvents.BrowserError, props);
  }
}
