import { Component, ContentChild, Input } from '@angular/core';
import { SharedEthicEmails, SharedLinks } from '@ethic/shared/configurations';
import { AnalyticsService, HubAnalyticsEvents } from '@hub/core';
import { TopBarComponent } from '@hub/shared/top-bar/top-bar.component';
import { PageFooterDirective } from './page-footer.directive';
import { PageHeaderDirective } from './page-header.directive';

@Component({
  selector: 'hub-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  @Input() wideContent = false;
  @Input() wideFooter = false;

  @ContentChild(PageHeaderDirective) topBarDirective?: PageHeaderDirective;
  @ContentChild(TopBarComponent) topBarComponent?: TopBarComponent;
  @ContentChild(PageFooterDirective) footerDirective?: PageFooterDirective;

  readonly careersLink = {
    name: 'careers page',
    url: SharedLinks.EthicCareers,
  };

  readonly footerLinks = [
    {
      name: 'Terms of Service',
      url: SharedLinks.TermsOfService,
    },
    {
      name: 'Privacy Policy',
      url: SharedLinks.PrivacyPolicy,
    },
    {
      name: 'ADV Part 2A',
      url: SharedLinks.AdvPart2a,
    },
  ];

  readonly ethicLink = SharedLinks.EthicMarketingSite;

  readonly partnershipEmail = SharedEthicEmails.Partnership;
  readonly communityEmail = SharedEthicEmails.Community;

  get isTopBarProjected() {
    return !!(this.topBarComponent || this.topBarDirective);
  }

  get isFooterProjected() {
    return !!this.footerDirective;
  }

  constructor(private analytics: AnalyticsService) {}

  trackLink(link: { name: string; url: string }) {
    const props = {
      name: link.name,
      url: link.url,
    };
    this.analytics.trackEvent(HubAnalyticsEvents.LinkClicked, props);
  }
}
