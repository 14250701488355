import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@hub-environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LearningContent } from '../../features/pillars/interfaces/pillars.interface';
import { AvailableVia, ResourceType } from '../../features/pillars/interfaces/webflow.enums';
import { PillarInfo } from '../models/pillar-info';

interface WebflowCollection {
  items: any[];
}

@Injectable({
  providedIn: 'root',
})
export class PillarsService {
  pillars$ = new BehaviorSubject<PillarInfo[]>([]);
  learningContent$ = new BehaviorSubject<LearningContent[]>([]);

  private readonly bucketName = environment.googleStorageBucket;

  readonly pillarJsonUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Fpillars.json?alt=media`;
  readonly learningContentJsonUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Flearning-content.json?alt=media`;

  private loading = false;
  private loaded = false;

  constructor(private http: HttpClient) {}

  loadPillars() {
    // Prevent double loading when consumer try to load pillars while its already loading
    if (this.loading || this.loaded) {
      return;
    }
    this.loading = true;
    this.getPillars().subscribe((pillars) => {
      this.loading = false;
      this.loaded = true;
      this.pillars$.next(pillars);
    });
  }

  loadLearningContent() {
    this.getLearningContent().subscribe((learningContent) =>
      this.learningContent$.next(learningContent)
    );
  }

  // todo return [] if error
  private getPillars() {
    return this.http
      .get<WebflowCollection>(this.pillarJsonUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((pillars) => this.replaceIcons(pillars)));
  }

  // todo return [] if error
  private getLearningContent() {
    return this.http.get<WebflowCollection>(this.learningContentJsonUrl).pipe(
      map((res) => res.items),
      map((items) => this.adaptWebflowValues(items))
    );
  }

  private replaceIcons(pillars) {
    return pillars.items.map((pillar) => ({
      ...pillar,
      icon: {
        ...pillar.icon,
        url: `https://storage.googleapis.com/${
          this.bucketName
        }/assets/pillar-icons/${encodeURIComponent(pillar.name)}.svg`,
      },
      ['wallpaper-icon']: {
        ...pillar['wallpaper-icon'],
        url: `https://storage.googleapis.com/${
          this.bucketName
        }/assets/pillar-patterns/${encodeURIComponent(pillar.name)}.png`,
      },
    }));
  }

  // workaround for mapping ID's from webflow to real values
  private adaptWebflowValues(items: any[]): LearningContent[] {
    return items.map((item) => {
      const availableViaTuple = Object.entries(AvailableVia).find(
        (tuple) => tuple[0] === item['available-via']
      );
      const resourceTypeTuple = Object.entries(ResourceType).find(
        (tuple) => tuple[0] === item['resource-type']
      );

      return {
        ...item,
        ['available-via']: availableViaTuple && availableViaTuple[1],
        ['resource-type']: resourceTypeTuple && resourceTypeTuple[1],
      };
    });
  }
}
