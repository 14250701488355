import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HubAnalyticsEvents } from './analytics-events';
import { AnalyticsService } from './analytics.service';

@Injectable()
export class HubAnalyticsInterceptor implements HttpInterceptor {
  // in milliseconds
  private readonly maxResponseTime = 5000;

  constructor(private analytics: AnalyticsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = window.performance.now();
    let responseReceived: boolean;
    let errorMessage: string;
    let responseCode: number;

    return next.handle(req).pipe(
      tap(
        (event) => (responseReceived = event instanceof HttpResponse),
        (error) => {
          if (error instanceof HttpErrorResponse) {
            errorMessage = error.error?.errorMessage || error.error;
            responseCode = error.status;
          }
        }
      ),
      finalize(() => {
        const responseTime = window.performance.now() - started;

        if ((responseReceived && responseTime > this.maxResponseTime) || errorMessage) {
          const props = {
            ['Http Method']: req.method,
            ['Request Url']: req.urlWithParams,
            // logging time in seconds instead of milliseconds
            ['Response Time']: Math.round(responseTime / 1000),
          };

          if (errorMessage) {
            props['Error Message'] = errorMessage;
            props['Response Code'] = responseCode;
          }

          this.analytics.trackEvent(
            errorMessage ? HubAnalyticsEvents.ServerError : HubAnalyticsEvents.HttpRequest,
            props
          );
        }
      })
    );
  }
}
