import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethRemovePrefix',
  pure: true,
})
export class AppRemovePrefixPipe implements PipeTransform {
  transform(value: any, prefixes: string | string[]): any {
    prefixes = Array.isArray(prefixes) ? prefixes : [prefixes];
    if (!prefixes || !prefixes.length) {
      return value;
    }
    const stringValue = value.toString().trim().toLowerCase();
    const prefix = prefixes.find((pr) => stringValue.startsWith(pr.toLowerCase()));
    return prefix ? value.substring(prefix.length, stringValue.length).trim() : value;
  }
}
