import { Component, Input } from '@angular/core';
import { SnackBarType } from '../snack-bar-type.interface';

@Component({
  selector: 'eth-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  @Input() action: SnackBarType;
  @Input() message: string;
}
