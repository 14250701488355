import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { UserPillar } from '../models/user-pillars';
import { AccountProfile, SustainabilityProfileUpdate, UserProfile } from '../models/user-profile';
import { UserProfileExtension } from '../models/user-profile-extension';
import { HubAnalyticsEvents } from './analytics-events';
import { AnalyticsService } from './analytics.service';
import { PillarsService } from './pillars.service';
@Injectable({ providedIn: 'root' })
export class ProfileDataService {
  private readonly userProfileUrl = '/api/hub/userProfile';
  private readonly userProfileExtensionUrl = '/api/hub/userProfileExtension';

  private readonly userProfile$ = new BehaviorSubject<UserProfile | null>(null);
  private readonly profileExtension$ = new BehaviorSubject<UserProfileExtension | null>(null);

  constructor(
    private http: HttpClient,
    private pillarsService: PillarsService,
    private snackBar: MatSnackBar,
    private analytics: AnalyticsService
  ) {}

  getProfile(): Observable<UserProfile> {
    const profile$ = this.userProfile$.pipe(
      filter((profile) => profile !== null)
    ) as Observable<UserProfile>;

    if (this.userProfile$.value) {
      return profile$;
    }

    return this.getProfileData().pipe(switchMap(() => profile$));
  }

  private getProfileData() {
    return this.fetchUserProfile().pipe(
      map((profileData) => {
        return {
          ...profileData,
          pillars: profileData.pillars.sort((a, b) => (a.name >= b.name ? 1 : -1)),
        };
      }),
      tap((userProfile) => this.userProfile$.next(userProfile))
    );
  }

  // TODO: implement a more reliable way to check whether the user is authorized
  isAuthorized(): Observable<boolean> {
    return this.getProfile().pipe(map((profile) => !!profile?.email));
  }

  updateAccountProfile(accountProfile: AccountProfile) {
    if (!accountProfile.firstName) {
      return throwError('First name is required');
    }
    if (!accountProfile.lastName) {
      return throwError('Last name is required');
    }

    return this.getProfile().pipe(
      take(1),
      tap(() =>
        this.analytics.trackEvent(HubAnalyticsEvents.ProfileUpdated, {
          affectedSection: 'account',
        })
      ),
      switchMap((userProfile) =>
        this.updateProfile({
          ...userProfile,
          ...accountProfile,
        })
      )
    );
  }

  updateSustainabilityProfile(sustainabilityProfile: SustainabilityProfileUpdate) {
    return this.getProfile().pipe(
      take(1),
      tap(() =>
        this.analytics.trackEvent(HubAnalyticsEvents.ProfileUpdated, {
          affectedSection: 'sustainability',
        })
      ),
      switchMap((userProfile) =>
        this.updateProfile({
          ...userProfile,
          ...sustainabilityProfile,
        })
      )
    );
  }

  private updateProfile(profile: UserProfile) {
    return this.http.put<UserProfile>(this.userProfileUrl, profile).pipe(
      tap(() => {
        profile.pillars.sort((a, b) => (a.name >= b.name ? 1 : -1));
        this.userProfile$.next(profile);
      })
    );
  }

  deleteProfile(confirmEmail: string, reason: string) {
    return this.http
      .request('delete', this.userProfileUrl, { body: { confirmEmail, reason } })
      .pipe(
        tap(() => {
          this.analytics.trackEvent(HubAnalyticsEvents.ProfileDeleted);
          this.analytics.setUserProperties({ profileDeleted: true });
        })
      );
  }

  loadProfileExtension() {
    this.http.get<UserProfileExtension>(this.userProfileExtensionUrl).subscribe((data) => {
      this.profileExtension$.next(data);
    });
  }

  getUserSequenceNumber(): Observable<number> {
    return this.profileExtension$.pipe(
      map((data) => {
        if (data) {
          return data.userSequenceNumber;
        } else {
          return 1;
        }
      })
    );
  }

  getOtherUsersCount(): Observable<number> {
    return this.profileExtension$.pipe(
      map((data) => {
        if (data) {
          return data.totalUsers - 1;
        } else {
          return 0;
        }
      })
    );
  }

  /**
   * Returns list of pillars selected by user
   */
  getUserPillars() {
    return combineLatest([this.getProfile(), this.getPillarsList()]).pipe(
      map(([userProfile, pillars]) => this.findUserPillars(userProfile.pillars, pillars))
    );
  }

  private fetchUserProfile() {
    return this.http.get<UserProfile>(this.userProfileUrl).pipe(
      catchError((err) => {
        if (err?.status !== 401) {
          this.snackBar.open('Cannot load profile', '', {
            panelClass: 'warning-message-snackbar',
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        }
        return of(err);
      })
    );
  }

  /**
   * List of all pillars
   */
  private getPillarsList() {
    return this.pillarsService.pillars$.pipe(
      filter((pillars) => pillars.length > 0),
      take(1)
    );
  }

  /**
   * Match pillars send from survey to pillars from webflow
   */
  private findUserPillars(selectedPillars: UserPillar[], pillarsList: any[]) {
    if (!selectedPillars.length) {
      return [];
    }
    return selectedPillars
      .map((selectedPillar) =>
        pillarsList.find(
          (pillar) =>
            (selectedPillar.id && selectedPillar.id === pillar['engine-id-number']) ||
            pillar.name === selectedPillar.name
        )
      )
      .filter((pillar) => !!pillar);
  }
}
