import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { timer } from 'rxjs';

@Directive({
  selector: '[ethMessageOnClick]',
})
export class TooltipOnClickDirective extends MatTooltip implements AfterViewInit {
  readonly hideDelayMs = 1000;

  @HostListener('click')
  onClick() {
    this.disabled = false;
    this.hide();
    this.show();
    timer(this.hideDelayMs).subscribe(() => {
      this.hide();
      this.disabled = true;
    });
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.disabled = true;
  }

  @Input()
  get ethMessageOnClick() {
    return this.message;
  }
  set ethMessageOnClick(value: string) {
    this.message = value;
  }
}
