<header
  *ngIf="isTopBarProjected"
  class="mat-elevation-z2"
  ethContextualSnackBarId="header-notification"
  ethContextualSnackBarHorizontalPosition="start"
  ethContextualSnackBarVerticalPosition="bottom"
>
  <ng-content select="[hubPageHeader]"></ng-content>
</header>
<section class="main-container" fxLayout fxLayoutAlign="center" fxFlex>
  <div fxFlex [class]="wideContent ? 'full-width' : 'limited-width'" fxLayout="column">
    <ng-content select="[hubPageContent]"></ng-content>
  </div>
</section>
<footer *ngIf="isFooterProjected" fxLayout="column">
  <ng-content select="[hubPageFooter]"></ng-content>

  <section class="footer-link-container">
    <div class="footer-links-wrapper limited-width">
      <div class="footer-links-block partnership">
        <div class="link-block-title" fxLayout fxLayoutAlign="flex-start center">
          <div class="link-block-icon">✨</div>
          <div class="link-block-label">Partnerships</div>
        </div>
        <div class="link-block-content">
          If you are a cool company working towards a sustainable future and interested in more you
          can to do help, please get in touch
          <a class="links-block-link" href="mailto:{{ partnershipEmail }}">{{
            partnershipEmail
          }}</a>
        </div>
      </div>
      <div class="footer-links-block community">
        <div class="link-block-title" fxLayout fxLayoutAlign="flex-start center">
          <div class="link-block-icon">⚡</div>
          <div class="link-block-label">Community Collaboration</div>
        </div>
        <div class="link-block-content">
          We’re looking for people with expertise in different areas of sustainability who are
          excited to make a difference. If that’s you, get in touch at
          <a class="links-block-link" href="mailto:{{ communityEmail }}">{{ communityEmail }}</a>
        </div>
      </div>
      <div class="footer-links-block job">
        <div class="link-block-title" fxLayout fxLayoutAlign="flex-start center">
          <div class="link-block-icon">⭐</div>
          <div class="link-block-label">Job at Ethic</div>
        </div>
        <div class="link-block-content">
          We are expanding our vision and also our team. If you think you would be a great fit for
          working at Ethic, then we would be excited to meet you. See our
          <a
            class="links-block-link"
            [href]="careersLink.url"
            (click)="trackLink(careersLink)"
            target="_blank"
          >
            {{ careersLink.name }}</a
          >
        </div>
      </div>
    </div>
  </section>

  <div
    fxFlex
    class="terms-and-services-container"
    fxLayout="column"
    fxLayoutAlign="space-between center"
  >
    <div class="limited-width terms-and-services-content">
      <div class="terms-and-services-row">
        <a
          [href]="ethicLink"
          target="_blank"
          class="simple-link"
          (click)="trackLink({ url: ethicLink, name: 'Powered by Ethic' })"
          >Powered by Ethic</a
        >
      </div>
      <div class="terms-and-services-row">
        Ethic ® is a registered trademark of Ethic Inc. © Copyright 2021 Ethic Inc. All rights
        reserved.
      </div>
      <div class="terms-and-services-row">
        Ethic Inc. is an SEC Registered Investment Adviser located in New York, NY. Registration of
        an investment adviser does not imply any specialized level of skill or training. Information
        provided herein is for informational purposes only and does not intend to make an offer or
        solicitation for the sale or purchase of any specific securities, investments, or investment
        strategies. Ethic does not render any legal, accounting, or tax advice. or Ethic may only
        transact business or render personalized investment advice in those states and international
        jurisdictions where it is registered/filed notice or is otherwise excluded or exempted from
        registration requirements. Any communications with prospective clients residing in states or
        international jurisdictions where Ethic and its representatives are not notice filed or
        licensed shall be limited so as not to trigger notice filing or licensing requirements.
        Nothing on this web site should be construed as personalized investment advice, which can
        only be provided in one-on-one communications. Ethic is not receiving compensation for
        including the listed companies or individuals on this website. This content is not sponsored
        or endorsed by any of the listed companies or individuals.
      </div>
      <div class="terms-and-services-row">
        <a
          *ngFor="let link of footerLinks"
          [href]="link.url"
          target="_blank"
          (click)="trackLink(link)"
        >
          {{ link.name }}
        </a>
      </div>
    </div>
  </div>
</footer>
