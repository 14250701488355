import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethCurrency',
  pure: true,
})
export class AppCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any, ccy: string): any {
    if (!ccy) {
      return '';
    }
    return this.currencyPipe.transform(value, ccy, 'symbol', '1.0-0');
  }
}
