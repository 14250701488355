import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImpactAreaDataService } from '@ethic/shared/impact-areas-data';
import { environment } from '@hub-environments/environment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ArticleInterface } from '../../features/articles/interfaces/article.interface';
import { AuthorInterface } from '../../features/articles/interfaces/author.interface';
import { EasySwitchInterface } from '../../features/articles/interfaces/easy-switch.interface';
import { SustainabilityTopic } from '../../features/articles/interfaces/sustainability-topic.interface';

interface WebflowCollection {
  items: any[];
}

@Injectable({ providedIn: 'root' })
export class WebflowDataService {
  headers$ = new BehaviorSubject<any[]>([]);
  dataPoints$ = new BehaviorSubject<any[]>([]);
  persons$ = new BehaviorSubject<any[]>([]);
  companies$ = new BehaviorSubject<any[]>([]);
  resources$ = new BehaviorSubject<any[]>([]);

  articles$ = new BehaviorSubject<ArticleInterface[]>([]);
  easySwitches$ = new BehaviorSubject<EasySwitchInterface[]>([]);
  sustainabilityTopics$ = new BehaviorSubject<SustainabilityTopic[]>([]);
  authors$ = new BehaviorSubject<AuthorInterface[]>([]);

  private readonly bucketName = environment.googleStorageBucket;

  readonly headersUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Fimpact-areas-headers.json?alt=media`;
  readonly datapointsUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Fimpact-areas-data.json?alt=media`;
  readonly personsUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Fimpact-areas-persons.json?alt=media`;
  readonly companiesUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Fimpact-areas-companies.json?alt=media`;
  readonly resourcesUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Fimpact-areas-resources.json?alt=media`;

  readonly articlesUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Farticles.json?alt=media`;
  readonly switchesUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Feasy-switches.json?alt=media`;
  readonly topicsUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Fsustainability-topics.json?alt=media`;
  readonly authorsUrl = `https://www.googleapis.com/download/storage/v1/b/${this.bucketName}/o/webflow%2Fauthors.json?alt=media`;

  constructor(private http: HttpClient, private impactAreaDataService: ImpactAreaDataService) {}

  loadHeaders() {
    this.http.get<WebflowCollection>(this.headersUrl).subscribe(({ items }) => {
      this.headers$.next(items);
    });
  }

  loadDataPoints() {
    this.http.get<WebflowCollection>(this.datapointsUrl).subscribe(({ items }) => {
      this.dataPoints$.next(items);
    });
  }

  loadPersons() {
    this.http.get<WebflowCollection>(this.personsUrl).subscribe(({ items }) => {
      this.persons$.next(items);
    });
  }

  loadCompanies() {
    this.http.get<WebflowCollection>(this.companiesUrl).subscribe(({ items }) => {
      this.companies$.next(items);
    });
  }

  loadResources() {
    this.http.get<WebflowCollection>(this.resourcesUrl).subscribe(({ items }) => {
      this.resources$.next(items);
    });
  }

  loadArticles() {
    this.http.get<WebflowCollection>(this.articlesUrl).subscribe(({ items }) => {
      this.articles$.next(items);
    });
  }

  loadEasySwitches$() {
    this.http.get<WebflowCollection>(this.switchesUrl).subscribe(({ items }) => {
      this.easySwitches$.next(items);
    });
  }

  loadSustainabilityTopics() {
    this.http.get<WebflowCollection>(this.topicsUrl).subscribe(({ items }) => {
      this.sustainabilityTopics$.next(items);
    });
  }

  loadAuthors() {
    this.http.get<WebflowCollection>(this.authorsUrl).subscribe(({ items }) => {
      this.authors$.next(items);
    });
  }

  getOrderedHeadersList() {
    if (!this.headers$.value.length) {
      this.loadHeaders();
    }
    return combineLatest([this.headers$, this.impactAreaDataService.getImpactAreas$()]).pipe(
      filter(([headers, impactAreas]) => !!headers?.length && !!impactAreas?.length),
      map(([headers, userImpactAreas]) => {
        return userImpactAreas.map((area) => ({
          ...area,
          slug: headers.find((header) => header['impact-id'] === area.id)?.slug,
          color: headers.find((header) => header['impact-id'] === area.id)?.color,
        }));
      })
    );
  }
}
