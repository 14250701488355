import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  CardListItemDirective,
  CardListTableDirective,
  ChangeDetectionIndicatorDirective,
  ClickPreventDefaultDirective,
  ClickStopPropagationDirective,
  ThousandSeparatorDirective,
  WindowEscapeDirective,
} from './directives';
import { FootnotesVisibleDirective } from './directives/footnotes-visible.directive';
import { PdfReadyDirective } from './directives/pdf-ready.directive';
import { TooltipOnClickDirective } from './directives/tooltip-onclick.directive';
import { TooltipVisibleDirective } from './directives/tooltip-visible.directive';
import {
  AppCurrencyPipe,
  AppPercentPipe,
  AppPercentPrecisePipe,
  AppRemovePrefixPipe,
  ArrayToStringPipe,
  CurrencyInputPipe,
  CurrencySymbolPipe,
  HighlightTextPipe,
  NameInitialsPipe,
  OrdinalSuffixPipe,
  PercentOfTrackingErrorPipe,
  PillarIconsPathPipe,
  PluralFormPipe,
  SectorIconPipe,
  ShowAnchorLinkPipe,
  TimeFromNowPipe,
  TrustItPipe,
} from './pipes';
import { HtmlLinkTargetPipe } from './pipes/html-link-target/html-link-target.pipe';
import { RemoveHtmlLinksPipe } from './pipes/remove-html-links/remove-html-links.pipe';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { SnackBarComponent } from './services/notification/snack-bar/snack-bar.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [
    PillarIconsPathPipe,
    HighlightTextPipe,
    ArrayToStringPipe,
    TruncatePipe,
    WindowEscapeDirective,
    ThousandSeparatorDirective,
    ClickStopPropagationDirective,
    ClickPreventDefaultDirective,
    ChangeDetectionIndicatorDirective,
    CardListItemDirective,
    CardListTableDirective,
    AppCurrencyPipe,
    AppPercentPipe,
    AppPercentPrecisePipe,
    TrustItPipe,
    ShowAnchorLinkPipe,
    AppRemovePrefixPipe,
    PercentOfTrackingErrorPipe,
    SectorIconPipe,
    NameInitialsPipe,
    TimeFromNowPipe,
    CurrencySymbolPipe,
    CurrencyInputPipe,
    TooltipVisibleDirective,
    FootnotesVisibleDirective,
    PdfReadyDirective,
    SnackBarComponent,
    RemoveHtmlLinksPipe,
    OrdinalSuffixPipe,
    TooltipOnClickDirective,
    HtmlLinkTargetPipe,
    PluralFormPipe,
  ],
  exports: [
    PillarIconsPathPipe,
    HighlightTextPipe,
    ArrayToStringPipe,
    TruncatePipe,
    WindowEscapeDirective,
    ThousandSeparatorDirective,
    ClickStopPropagationDirective,
    ClickPreventDefaultDirective,
    ChangeDetectionIndicatorDirective,
    CardListItemDirective,
    CardListTableDirective,
    AppCurrencyPipe,
    AppPercentPipe,
    AppPercentPrecisePipe,
    TrustItPipe,
    ShowAnchorLinkPipe,
    AppRemovePrefixPipe,
    PercentOfTrackingErrorPipe,
    SectorIconPipe,
    NameInitialsPipe,
    TimeFromNowPipe,
    CurrencySymbolPipe,
    CurrencyInputPipe,
    CommonModule,
    TooltipVisibleDirective,
    FootnotesVisibleDirective,
    SnackBarComponent,
    PdfReadyDirective,
    RemoveHtmlLinksPipe,
    OrdinalSuffixPipe,
    TooltipOnClickDirective,
    HtmlLinkTargetPipe,
    PluralFormPipe,
  ],
  providers: [DatePipe, AppCurrencyPipe, DecimalPipe, PercentPipe, CurrencyPipe],
})
export class NgKitModule {}
