import { Routes } from '@angular/router';
import { DevHostModeGuard } from './features/login-dev/login-dev/dev-host-mode.guard';
import { DevModeGuard } from './features/login-dev/login-dev/dev-mode.guard';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

export const hubAppRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('@hub/features/private/private.module').then((m) => m.PrivateModule),
  },
  {
    path: 'mission',
    loadChildren: () => import('@hub/features/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'tech',
    loadChildren: () => import('@hub/features/tech/tech.module').then((m) => m.TechModule),
    canLoad: [DevHostModeGuard],
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
    data: {
      title: '404',
    },
  },
  {
    path: 'login-dev',
    loadChildren: () =>
      import('@hub/features/login-dev/login-dev/login-dev.module').then((m) => m.LoginDevModule),
    canLoad: [DevModeGuard],
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
];
