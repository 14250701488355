import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[ethWindowEscape]',
})
export class WindowEscapeDirective {
  @Output() ethWindowEscape = new EventEmitter();

  @HostListener('document:keydown.escape') onKeydownHandler() {
    this.ethWindowEscape.emit();
  }
}
