import { Directive, DoCheck, ElementRef, HostBinding } from '@angular/core';
import * as moment from 'moment-mini';

@Directive({
  selector: '[ethChangeDetectionIndicator]',
})
export class ChangeDetectionIndicatorDirective implements DoCheck {
  @HostBinding('style.background-color') bgColor = 'black';
  @HostBinding('style.color') color = 'white';
  @HostBinding('style.padding') padding = '0 4px';

  checkCounter = 0;

  constructor(private el: ElementRef) {}

  ngDoCheck() {
    ++this.checkCounter;
    this.el.nativeElement.innerHTML = `${moment().format('HH:mm:ss.SSS')} Checks: ${
      this.checkCounter
    }`;
  }
}
