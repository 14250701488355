import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { HubCommonModule } from '../hub-common/hub-common.module';
import { LayoutModule } from '../layout/layout.module';
import { TopBarModule } from '../top-bar/top-bar.module';
import { PageNotFoundComponent } from './page-not-found.component';

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [HubCommonModule, LayoutModule, MatButtonModule, RouterModule, TopBarModule],
  exports: [PageNotFoundComponent],
})
export class PageNotFoundModule {}
