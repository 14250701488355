import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DevHostModeGuard implements CanLoad {
  constructor(@Inject(DOCUMENT) private document: any) {}

  canLoad() {
    const hostname = this.document.location.hostname;

    return hostname === 'localhost' || hostname === 'community.ethic.tech';
  }
}
