import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethPercentOfTrackingError',
  pure: true,
})
export class PercentOfTrackingErrorPipe implements PipeTransform {
  transform(value: number): string {
    if (value === 0) {
      return '0%';
    }
    const num: number = Math.round(value * 10000) / 100;
    const numStr: string = num.toString();

    return (num >= 1 ? numStr : numStr.slice(1)) + '%';
  }
}
