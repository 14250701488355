import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  overlayRef: OverlayRef;

  options: any;

  constructor(private overlay: Overlay) {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const scrollStrategy = this.overlay.scrollStrategies.block();

    this.overlayRef = this.overlay.create({
      height: '100%',
      width: '100%',
      positionStrategy: positionStrategy,
      scrollStrategy: scrollStrategy,
    });
  }

  openOverlay<T>(component: any, options?: T) {
    this.options = options;
    const overlayPortal = new ComponentPortal(component, null);
    this.overlayRef.attach(overlayPortal);
  }

  getOptions() {
    return this.options;
  }

  closeOverlay() {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.detach();
    this.options = null;
  }
}
