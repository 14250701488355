import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ImpactAreaItem } from './impact-area';

@Injectable({ providedIn: 'root' })
export class ImpactAreaDataService {
  private impactAreas = [
    {
      id: 1,
      name: 'Living',
      shortName: 'Live',
      displayOrder: 1,
      color: '#14D9F5',
      tagline:
        'Changing your relationship with consumption means taking a look at what you buy but also at the waste you leave behind.',
    },
    {
      id: 2,
      name: 'Giving',
      shortName: 'Give',
      displayOrder: 5,
      color: '#1fd3a0',
      tagline:
        "Even small donations to impactful causes can have tangible impacts. But if you can't donate money, you can still volunteer your time.",
    },
    {
      id: 3,
      color: '#ff92dd',
      name: 'Working',
      shortName: 'Work',
      displayOrder: 2,
      tagline:
        "Seeking more impact at work can mean looking for a new job that's more aligned with your values or taking initiative to make change at your current workplace.",
    },
    {
      id: 4,
      color: '#fd8a66',
      name: 'Learning',
      shortName: 'Learn',
      displayOrder: 3,
      tagline:
        'Sustainability education can be broad, orienting you to the different issues out there, or deep, helping you understand the best ways to have impact in a particular area.',
    },
    {
      id: 5,
      color: '#ac92ff',
      name: 'Advocating',
      shortName: 'Advocate',
      displayOrder: 6,
      tagline:
        "Advocacy doesn't just mean going to protests. It can also mean organizing your community around issues, helping out with campaigns, and joining local decision-making groups like school boards.",
    },
    {
      id: 6,
      color: '#f2d024',
      name: 'Creating',
      shortName: 'Create',
      displayOrder: 4,
      tagline:
        'There are endless ways to express yourself about the things you find most important. Where to start is unique to you. ',
    },
    {
      id: 7,
      color: '#3d5afe',
      name: 'Investing',
      shortName: 'Invest',
      displayOrder: 7,
      tagline:
        "Whether it's divesting from companies making negative impacts, investing in those doing good, or voting with your shares, there are many ways to use your money to create better incentives in the market.",
    },
  ];

  getImpactAreas() {
    return [...this.impactAreas];
  }

  getImpactAreas$(): Observable<ImpactAreaItem[]> {
    const data = [...this.impactAreas];
    data.sort((a, b) => {
      const aOrder = a.displayOrder;
      const bOrder = b.displayOrder;
      if (aOrder < bOrder) {
        return -1;
      }
      if (aOrder > bOrder) {
        return 1;
      }
      return 0;
    });
    return of(data);
  }
}
