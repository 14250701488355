import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { AssetLoadingResult, AssetLoadingStatus } from './asset-loading-result';

@Injectable({ providedIn: 'root' })
export class AssetLoadingService {
  private scripts: { [src: string]: AssetLoadingResult } = {};
  private styles: { [src: string]: AssetLoadingResult } = {};

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2
  ) {}

  loadScripts(sources: string[]): Observable<AssetLoadingResult[]> {
    const observables = sources.map((src) => this.loadScript(src));
    return forkJoin(observables);
  }

  loadStyle(src: string, id: string): Observable<AssetLoadingResult> {
    return new Observable((observer) => {
      let styleLoadingResult = this.styles[src];

      if (!styleLoadingResult) {
        styleLoadingResult = this.styles[src] = { src, status: null };
      } else if (styleLoadingResult.status === AssetLoadingStatus.Failed) {
        observer.next(styleLoadingResult);
        observer.complete();
        return;
      }

      const renderer = this.rendererFactory.createRenderer(null, null);
      const docHead = this.document.head;
      const existingStyleLink = docHead.querySelector(`#${id}`);

      const linkEl: HTMLElement = renderer.createElement('link');
      renderer.setAttribute(linkEl, 'rel', 'stylesheet');
      renderer.setAttribute(linkEl, 'type', 'text/css');
      renderer.setAttribute(linkEl, 'id', id);
      renderer.setAttribute(linkEl, 'href', src);

      renderer.setProperty(linkEl, 'onload', () => {
        if (existingStyleLink) {
          renderer.removeChild(docHead, existingStyleLink);
        }
        styleLoadingResult.status = AssetLoadingStatus.Loaded;
        observer.next(styleLoadingResult);
        observer.complete();
      });

      renderer.setProperty(linkEl, 'onerror', () => {
        this.styles[src].status = AssetLoadingStatus.Failed;
        observer.error(styleLoadingResult);
      });

      renderer.insertBefore(docHead, linkEl, docHead.children[0]);
    });
  }

  private loadScript(src: string): Observable<AssetLoadingResult> {
    return new Observable((observer) => {
      if (!this.scripts[src]) {
        this.scripts[src] = { src, status: null };
      }
      const scriptLoadingResult = this.scripts[src];

      if (scriptLoadingResult.status === 'loaded') {
        observer.next(scriptLoadingResult);
        observer.complete();
        return;
      }

      const renderer = this.rendererFactory.createRenderer(null, null);
      const script = renderer.createElement('script');

      renderer.setAttribute(script, 'rel', 'script');
      renderer.setAttribute(script, 'type', 'text/javascript');
      renderer.setAttribute(script, 'src', src);

      script.onload = () => {
        scriptLoadingResult.status = AssetLoadingStatus.Loaded;
        observer.next(scriptLoadingResult);
        observer.complete();
      };

      script.onerror = () => observer.error(scriptLoadingResult);

      this.document.head.appendChild(script);
    });
  }
}
