import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethCurrencySymbol',
  pure: true,
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(ccy: string): any {
    if (!ccy) {
      return '';
    }
    return getCurrencySymbol(ccy, 'wide');
  }
}
