import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethTruncate',
  pure: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number = 100, ellipsis: string = '...') {
    let truncateAt = value.substr(0, limit).lastIndexOf(' ');
    truncateAt = truncateAt === -1 ? limit : truncateAt;
    return value.length > truncateAt ? value.substr(0, truncateAt) + ellipsis : value;
  }
}
