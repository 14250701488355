import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Subject } from 'rxjs';

/** Custom form field directive that can be used inside <mat-form-field> with input.
 This directive implements ControlValueAccessor, MatFormFieldControl and various methods which need
 for API of mat-form-field so that directive can work with formControl and ngModel
 and react to all mat-form-field states (.mat-form-field-invalid, ng-touched, etc.)
 */
@Directive({
  selector: 'input[ethThousandSeparator]',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: forwardRef(() => ThousandSeparatorDirective),
    },
  ],
})
export class ThousandSeparatorDirective
  implements ControlValueAccessor, MatFormFieldControl<number>
{
  private _ngValue: number | null;

  stateChanges = new Subject<void>();
  id: string;
  focused = false;

  get empty(): boolean {
    return this._ngValue == null;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState(): boolean {
    return !!(this.ngControl.invalid && this.ngControl.dirty);
  }

  get value(): number | null {
    return this._ngValue;
  }

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private _focusMonitor: FocusMonitor,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this._focusMonitor.monitor(elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  onChange = (_: number | null) => {
    // do nothing
  };
  onTouched = () => {
    // do nothing
  };

  setDescribedByIds(_: string[]): void {
    // do nothing
  }

  onContainerClick(_: MouseEvent): void {
    // do nothing
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    let textValue = this.cleanUpNonNumberSymbols(value);
    textValue = this.formatDecimals(textValue);
    this.formatDisplayValue(textValue);
    this._ngValue = this.toNumber(textValue);
    this.onChange(this._ngValue);
  }

  writeValue(value: string | number) {
    this._ngValue = this.toNumber(value);
    this.formatDisplayValue('' + value);
  }

  registerOnChange(fn: (value: number | null) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  private toNumberWithCommas(x: string) {
    const integer = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return integer;
  }

  private formatDisplayValue(value: string | null) {
    if (value !== null) {
      this.elementRef.nativeElement.value = this.toNumberWithCommas(value);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private toNumber(value: string | number): number | null {
    if (typeof value === 'number') {
      return value;
    }
    return isNaN(parseFloat(value)) ? null : parseFloat(value);
  }

  private cleanUpNonNumberSymbols(value: string) {
    return value.replace(/[^0-9.]/g, '');
  }

  private formatDecimals(value: string, decimalLimit: number = 2) {
    const dotPosition = value.lastIndexOf('.');
    if (dotPosition === -1) {
      return value;
    }
    const integer = value.substr(0, dotPosition).replace('.', '');
    const fraction = value.substring(dotPosition).substring(0, decimalLimit + 1);
    return integer + fraction;
  }
}
