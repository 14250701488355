import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FlexLayoutModule.withConfig({
      useColumnBasisZero: false,
    }),
  ],
  exports: [CommonModule, HttpClientModule, FlexLayoutModule],
})
export class HubCommonModule {}
