import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SocialShareSettings } from './social-share-settings';
import { SOCIAL_SHARE_SETTINGS_TOKEN } from './social-share-settings-token';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class SocialShareModule {
  /**
   * You should provide valid social network settings required to initialize sharing features.
   * These settings will be provided to the entire application by `SOCIAL_SHARE_SETTINGS_TOKEN`
   * token.
   *
   * @param settings Social network settings
   */
  static forRoot(settings: SocialShareSettings): ModuleWithProviders<SocialShareModule> {
    return {
      ngModule: SocialShareModule,
      providers: [
        {
          provide: SOCIAL_SHARE_SETTINGS_TOKEN,
          useValue: settings,
        },
      ],
    };
  }
}
