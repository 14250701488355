import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarType } from './snack-bar-type.interface';
import { SnackBarComponent } from './snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  defaultConfig: MatSnackBarConfig = {
    duration: 2500,
    verticalPosition: 'top',
    horizontalPosition: 'right',
    panelClass: 'eth-snack-bar',
  };

  constructor(public snackBar: MatSnackBar) {}

  error(message: string, config: MatSnackBarConfig = {}) {
    this.show('error', message, config);
  }

  warning(message: string, config: MatSnackBarConfig = {}) {
    this.show('warning', message, config);
  }

  success(message: string, config: MatSnackBarConfig = {}) {
    this.show('success', message, config);
  }

  info(message: string, config: MatSnackBarConfig = {}) {
    this.show('info', message, config);
  }

  show(action: SnackBarType, message: string, config: MatSnackBarConfig = {}) {
    config = { ...this.defaultConfig, ...config };
    const snackBar = this.snackBar.openFromComponent(SnackBarComponent, config);
    snackBar.instance.action = action;
    snackBar.instance.message = message;
  }
}
