/* eslint-disable @typescript-eslint/naming-convention */

export enum ResourceType {
  'afa49d2208f6b755761aae020a304c5b' = 'Films',
  '4ddb475287d447fca1ed9094261bcd0e' = 'Books',
  'c6c3dce1aeeedc3c9b4c381714f37b3b' = 'Ethic Insights',
}

export enum AvailableVia {
  '0947e1213cdfff6fb9944630c6753b19' = 'Amazon',
  'e51a326d514fce5a9bbb36ebf083073f' = 'Bookstores & Online',
  '3720d5c23c7acc0b315c3bfdac0c6800' = 'Netflix',
  '467cfe19e2188087e655abea467497be' = 'YouTube',
  '7ea8da6308312feef91db7a2ff1bf9b1' = 'HBO',
  '809a2d9754f2499a2c5647b0f0ad5de6' = 'PBS',
  'a1fd25dc131c382c661c3318ae640900' = 'Ethic Insights',
  'eadd9adcd2ea6165bf4a5e070e04fcb5' = 'Pixar',
}
