<hub-page>
  <div hubPageHeader class="top-bar-container">
    <div class="slogan" fxFlexAlign="center">
      <span
        [@colorAnimation]="colorAnimationService.getAnimationConfig(animationPhase)"
        (@colorAnimation.done)="restartAnimation()"
        >Sustainability</span
      >
      for Everyone
    </div>

    <div class="logo" fxFlexAlign="center" fxFlex="0 0 auto"></div>
  </div>
  <div hubPageContent fxLayout="column" fxLayoutGap="24px">
    <div class="main">
      <div
        class="text-404"
        [@colorAnimation]="colorAnimationService.getAnimationConfig(animationPhase)"
        (@colorAnimation.done)="restartAnimation()"
      >
        404
      </div>
      <div class="desc">The page you are looking for doesn't exist or has moved.</div>
    </div>
  </div>
</hub-page>
