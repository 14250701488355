import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethPlural',
  pure: true,
})
export class PluralFormPipe implements PipeTransform {
  transform(value: string, amount: number, specialPlural?: string) {
    return amount === 1 ? value : specialPlural || value + 's';
  }
}
