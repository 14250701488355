import { animate, state, style, transition, trigger } from '@angular/animations';

export const colorAnimation = trigger('colorAnimation', [
  state(
    'init',
    style({
      filter: 'hue-rotate({{firstPhaseHue}}deg)',
    }),
    { params: { firstPhaseHue: 0 } }
  ),
  state(
    'start',
    style({
      filter: `hue-rotate(0deg)`,
    })
  ),
  state(
    'end',
    style({
      filter: `hue-rotate(180deg)`,
    })
  ),
  transition('start <=> end', [animate('15s')]),
  transition('init => start', [animate('{{firstPhaseDuration}}ms')]),
  transition('init => end', [animate('{{firstPhaseDuration}}ms')]),
]);
