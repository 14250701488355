import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HubAnalyticsInterceptor } from './analytics-interceptor.service';
import { AuthInterceptor } from './auth.interceptor';

/**
 * Http interceptor providers in outside-in order.
 * They will be processed in the provided order from the first to the last.
 */

export const hubInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HubAnalyticsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
