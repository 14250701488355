import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethArrayToString',
})
export class ArrayToStringPipe implements PipeTransform {
  transform(value: any, divider: string = ', '): any {
    if (!value || !Array.isArray(value)) {
      return '';
    }
    return value.join(divider);
  }
}
