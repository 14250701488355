import { animate, sequence, style, transition, trigger } from '@angular/animations';

export enum BoxShadowState {
  On = 'on',
  Off = 'off',
}

const boxShadowOut =
  '0px 7px 8px -4px rgba(0, 0, 0, 0.2), ' +
  '0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)';
const boxShadowIn =
  '0 2px 1px -1px rgba(0,0,0,.2),' + '0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)';

const shadowDuration = '4000ms';

export const boxShadow = trigger('changeState', [
  transition(
    `${BoxShadowState.On}=>${BoxShadowState.Off}`,
    animate(
      '280ms',
      style({
        boxShadow: boxShadowIn,
      })
    )
  ),
  transition(
    `*=>${BoxShadowState.On}`,
    sequence([
      style({ boxShadow: boxShadowOut }),
      animate(shadowDuration, style({ boxShadow: boxShadowOut })),
      animate('280ms', style({ boxShadow: boxShadowIn })),
    ])
  ),
]);
