import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AssetLoadingResult } from '@ethic/ng-kit';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import packageInfo from '../../../../../../package.json';
import { AssetLoadingService } from '../asset-loading/asset-loading.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeLoaderService {
  private readonly defaultTheme = 'ethic-theme.css';
  private readonly defaultThemeUrl = `${this.defaultTheme}`;
  private readonly styleLinkId = 'theme-stylesheet';

  constructor(private assetLoader: AssetLoadingService, private location: Location) {}

  loadTheme(teamId?: string): Observable<AssetLoadingResult> {
    let styleUrl = teamId ? `team-${teamId}-theme.css` : this.defaultThemeUrl;
    // add a unique version for cache busting
    styleUrl += `?v=${packageInfo.version}`;
    // make path absolute with deploy url
    styleUrl = this.location.prepareExternalUrl(styleUrl);

    return this.assetLoader
      .loadStyle(styleUrl, this.styleLinkId)
      .pipe(catchError(() => this.assetLoader.loadStyle(this.defaultThemeUrl, this.styleLinkId)));
  }
}
