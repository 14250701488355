import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ImpactAreaDataService } from '@ethic/shared/impact-areas-data';
import { environment } from '@hub-environments/environment';
import { Angulartics2, Angulartics2GoogleGlobalSiteTag, Angulartics2Mixpanel } from 'angulartics2';
import mixpanel from 'mixpanel-browser';
import { BehaviorSubject, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserImpactArea } from '../models';
import { UserProfile } from '../models/user-profile';
import { HubAnalyticsEvents } from './analytics-events';
import { GoogleAnalyticsInitializerService } from './google-analytics-initializer.service';
import { PageTitleService } from './page-title-service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly window = this.document.defaultView as Window & typeof globalThis;
  private readonly googleAnalyticsEnabled = environment.googleAnalytics.enabled;
  private readonly mixpanelEnabled = environment.mixpanelAnalytics.enabled;

  private lastUrl: string | null = null;
  private lastPage: string | null = null;

  readonly initialized$ = new BehaviorSubject(false);

  constructor(
    private googleAnalyticsInitializer: GoogleAnalyticsInitializerService,
    private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
    private angulartics2Mixpanel: Angulartics2Mixpanel,
    private angulartics2: Angulartics2,
    private router: Router,
    private impactAreaDataService: ImpactAreaDataService,
    private pageTitleService: PageTitleService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.startRouterTracking();
  }

  init() {
    this.initAnalyticsProviders().then(() => this.initialized$.next(true));
  }

  private initAnalyticsProviders(): Promise<void> {
    if (this.mixpanelEnabled) {
      this.initMixpanel();
    }
    if (this.googleAnalyticsEnabled) {
      return this.initGoogleAnalytics();
    } else {
      return Promise.resolve();
    }
  }

  private async initGoogleAnalytics(): Promise<void> {
    await this.googleAnalyticsInitializer.init().toPromise();
    this.angulartics2GoogleGlobalSiteTag.startTracking();
  }

  private initMixpanel() {
    // Attaching mixpanel to window object needed for Angulartics2
    this.window['mixpanel'] = mixpanel;
    mixpanel.init(environment.mixpanelAnalytics.token, { debug: false });

    this.angulartics2Mixpanel.startTracking();
  }

  trackEvent(action: HubAnalyticsEvents, props?: Record<string, unknown>) {
    this.angulartics2.eventTrack.next({
      action,
      properties: {
        ...props,
        // We have to additionally wrap props into gstCustom property
        // in order to have them correctly parsed in Google Analytics
        ...(this.googleAnalyticsEnabled && props ? { gstCustom: props } : null),
      },
    });
  }

  startEventTimer(eventName: HubAnalyticsEvents) {
    mixpanel.time_event(eventName);
  }

  trackException(props: Record<string, unknown>) {
    this.angulartics2.exceptionTrack.next(props);
  }

  setUserProperties(props: Record<string, unknown>) {
    this.angulartics2.setUserProperties.next(props);

    if (this.googleAnalyticsEnabled) {
      // Google Analytics tracks only primitive values like string and number
      const gtagProps = { ...props };
      Object.entries(gtagProps)
        .filter(([_, value]) => typeof value === 'object')
        .forEach(([key, _]) => delete gtagProps[key]);

      if (Object.keys(gtagProps).length > 0) {
        // We also have to send user properties to Google Analytics
        // explicitly, since Angulartics2 doesn't do that in a proper way
        // (it doesn't use 'user_properties' parameter)
        this.window['gtag']('set', 'user_properties', gtagProps);
      }
    }
  }

  setUser(profile: UserProfile, userSequenceNumber: number) {
    const userId = '' + userSequenceNumber;
    this.angulartics2.setUsername.next(userId);

    const userProps = {
      city: profile.city,
      country: profile.country,
      impactAreas: this.extendAreaObj(profile.impactAreas),
      investmentStatus: profile.investmentStatus,
      learningAreas: this.extendAreaObj(profile.learningAreas),
      newsletterEnabled: profile.newsletterEnabled,
      pillars: profile.pillars,
      pillarIds: profile.pillars?.map((pillar) => pillar.id),
    };
    this.setUserProperties(userProps);
  }

  private startRouterTracking() {
    this.router.events
      .pipe(
        switchMap((event) => this.pageTitleService.page$.pipe(map((page) => ({ page, event }))))
      )
      .subscribe(({ page, event }) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        const currentUrl = event.url;
        const entityName = this.pageTitleService.entityName;
        page = entityName ? `${page} ${entityName}` : page;

        if (this.lastUrl) {
          this.trackEvent(HubAnalyticsEvents.PageLeft, {
            url: this.lastUrl,
            nextUrl: currentUrl,
            name: this.lastPage,
            entityName,
          });
        }
        this.trackEvent(HubAnalyticsEvents.PageLoaded, {
          url: currentUrl,
          name: page,
        });

        this.lastUrl = event.url;
        this.lastPage = page;
        this.startEventTimer(HubAnalyticsEvents.PageLeft);
      });
  }

  private extendAreaObj(areas: UserImpactArea[]) {
    const impactArea = this.impactAreaDataService.getImpactAreas();
    return areas.map((area) => ({
      ...area,
      name: impactArea.find((a) => a.id === area.id)?.name,
    }));
  }
}
