import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';

export const ATOM_API_SETTINGS = new InjectionToken<AtomApiSettings>('atom-api-settings');

export interface AtomApiSettings {
  baseUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class AtomApiService {
  // TODO: find out the reason we set it to true in the DAO service. Perhaps we don't need it.
  private readonly defaultOptions = { withCredentials: true };

  constructor(
    private httpClient: HttpClient,
    @Inject(ATOM_API_SETTINGS) private settings: AtomApiSettings
  ) {}

  get<T = any>(
    url: string,
    options?: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      params?: HttpParams | { [param: string]: string | string[] };
      reportProgress?: boolean;
      withCredentials?: boolean;
    }
  ) {
    return this.httpClient.get<T>(this.getFullUrl(url), this.getFullOptions(options));
  }

  post<T = any>(
    url: string,
    body: any | null,
    options?: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      params?: HttpParams | { [param: string]: string | string[] };
      reportProgress?: boolean;
      withCredentials?: boolean;
    }
  ) {
    return this.httpClient.post<T>(this.getFullUrl(url), body, this.getFullOptions(options));
  }

  put<T = any>(
    url: string,
    body: any | null,
    options?: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      withCredentials?: boolean;
    }
  ) {
    return this.httpClient.put<T>(this.getFullUrl(url), body, this.getFullOptions(options));
  }

  delete<T = any>(
    url: string,
    options?: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      params?: HttpParams | { [param: string]: string | string[] };
      reportProgress?: boolean;
      withCredentials?: boolean;
    }
  ) {
    return this.httpClient.delete<T>(this.getFullUrl(url), this.getFullOptions(options));
  }

  getFullUrl(subpath: string): string {
    return this.settings.baseUrl + '/' + subpath;
  }

  private getFullOptions<P>(options?: P): P | { withCredentials?: boolean } {
    return { ...this.defaultOptions, ...options };
  }
}
