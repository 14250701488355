import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethPillarIconsPath',
  pure: true,
})
export class PillarIconsPathPipe implements PipeTransform {
  transform(url: string): string {
    return `https://storage.googleapis.com/ethic-platform-assets/${url}.svg`;
  }
}
