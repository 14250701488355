export const environment = {
  production: true,
  greygooseBaseUrl: '/greygoose',
  googleStorageBucket: 'community-content-dev',
  engineApiSettings: {
    baseUrl: '/api',
  },
  authSettings: {
    loginPagesBaseUrl: '/hub/auth',
    loginPage: '/login.html',
  },
  googleAnalytics: {
    enabled: true,
    gstId: 'G-95CEDEGQC3',
  },
  mixpanelAnalytics: {
    enabled: true,
    token: 'd03696e70af407a5e60cf599d77862c4',
  },
  facebook: { appId: '2936906593225287' },
};
