import { Injectable } from '@angular/core';
import { duration } from './color-animation.config';
import { ColorAnimationPhase } from './color-animation.interface';

@Injectable({
  providedIn: 'root',
})
export class ColorAnimationService {
  private readonly ticksPerPhase = 180 / duration;
  private colorAnimationEnabled = true;

  disableAnimation(): void {
    this.colorAnimationEnabled = false;
  }

  getAnimationPhase() {
    return Math.floor(new Date().getSeconds() / duration) % 2 === 0 ? 'end' : 'start';
  }

  get hueRotateDeg() {
    const hueValue =
      ((new Date().getSeconds() % duration) + new Date().getMilliseconds() / 1000) *
      this.ticksPerPhase;
    return this.getAnimationPhase() === 'end' ? hueValue : 180 - hueValue;
  }

  get remainingDuration() {
    return (
      (duration - ((new Date().getSeconds() % duration) + new Date().getMilliseconds() / 1000)) *
      1000
    );
  }

  getAnimationConfig(phase: ColorAnimationPhase) {
    return {
      value: this.colorAnimationEnabled ? phase : null,
      params: {
        firstPhaseHue: this.colorAnimationEnabled ? this.hueRotateDeg : 0,
        firstPhaseDuration: this.colorAnimationEnabled ? this.remainingDuration : 0,
      },
    };
  }
}
