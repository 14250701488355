import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const pageLayoutStorageEntry = 'hubLayout';

interface PageLayout {
  isDarkMode: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  readonly isDarkMode$ = new BehaviorSubject(false);

  get isDarkMode() {
    return this.isDarkMode$.value;
  }

  constructor() {
    this.loadState();
  }

  setThemeMode(value: boolean) {
    this.isDarkMode$.next(value);
    this.saveState();
  }

  toggleThemeMode() {
    this.isDarkMode$.next(!this.isDarkMode$.value);
    this.saveState();
  }

  private loadState() {
    const stateStr = localStorage.getItem(pageLayoutStorageEntry);
    if (!stateStr) {
      return;
    }

    let state: PageLayout;
    try {
      state = JSON.parse(stateStr);
    } catch {
      localStorage.removeItem(pageLayoutStorageEntry);
      return;
    }

    if (typeof state.isDarkMode === 'boolean') {
      this.isDarkMode$.next(state.isDarkMode);
    }
  }

  private saveState() {
    const state: PageLayout = {
      isDarkMode: this.isDarkMode,
    };
    localStorage.setItem(pageLayoutStorageEntry, JSON.stringify(state));
  }
}
