import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { WindowService } from '../services/window/window.service';

@Directive({
  selector: '[ethTooltipVisible]',
})
export class TooltipVisibleDirective {
  isLargerThanTablet: boolean;
  constructor(
    private tempRef: TemplateRef<any>,
    private viewCont: ViewContainerRef,
    private windowService: WindowService
  ) {
    this.windowService.isLargerThanTablet$.pipe(take(1)).subscribe((isLargerThanTablet) => {
      this.isLargerThanTablet = isLargerThanTablet;
    });
  }

  @Input() set ethTooltipVisible(tooltips: string | string[]) {
    if (tooltips && tooltips[0] && this.isLargerThanTablet) {
      this.viewCont.createEmbeddedView(this.tempRef);
    } else {
      this.viewCont.clear();
    }
  }
}
