import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeployUrlService {
  private deployUrlValue = '';

  get deployUrl(): string {
    return this.deployUrlValue;
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.deployUrlValue = this.grabDeployUrl();
  }

  private grabDeployUrl() {
    const scripts = this.document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      const src = scripts[i].src;
      if (src?.match(/main.*?\.js$/)) {
        return src.replace(/main.*?\.js$/, '');
      }
    }
    return '';
  }
}
