import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethNameInitials',
})
export class NameInitialsPipe implements PipeTransform {
  transform(value: any, _?: any): any {
    if (!value.lName.length) {
      return value.fName[0];
    }

    return value.fName[0] + value.lName[0];
  }
}
